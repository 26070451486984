import React, { useState } from "react"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Modal from "react-modal"
import Carousel from "nuka-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

import "../styles/service.css"

Modal.setAppElement("#___gatsby")

/**
 * Page
 * 
 * The main service page component, used as a template by gatsby-node.js to generate service pages from data grabbed from the API.
 * 
 * @param {*} props pageContext is passed by gatsby-node.js, which should contain title, body, and a gallery object.
 */

const Page = props => {
  const { title, body, gallery } = props.pageContext

  return (
    <>
      <SEO title={title} />
      <div className="container servicePageContainer">
        <h3>{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        {gallery && <Gallery gallery={gallery} />}
      </div>
    </>
  )
}

/**
 * Gallery component.
 * 
 * 
 * @param {*} param0 
 */

const Gallery = ({ gallery }) => {
  const [modalState, setModalState] = useState({
    modalOpen: false,
    modalImgIndex: 0,
  })

  const toggleModal = modalImgIndex => {
    if (!modalImgIndex) modalImgIndex = 0
    setModalState({
      modalOpen: !modalState.modalOpen,
      modalImgIndex,
    })
  }

  return (
    <div className="examplesWrapper">
      {gallery.map(({ localFile, title }, index) => {
        let thisDivStyle = {
          outline: "none",
          border: "0",
          width: "125px",
          height: "125px",
          display: "inline-block",
          margin: "5px",
          padding: "0",
          cursor: "pointer",
        }

        // focal point data currently not available via directus,
        // commenting out to preserve the sacred math.
        //
        // // center image in box based on focal point.
        // const fp = asset.fp;
        // thisDivStyle.backgroundPosition = fp ? `${Math.round(fp.x * 100)}% ${Math.round(fp.y * 100)}%` : "50% 50%"

        return (
          <button
            key={index}
            style={thisDivStyle}
            onClick={() => toggleModal(index)}
            // onKeyUp={() => toggleModal(index)}
          >
            <Img fadeIn={true} fluid={localFile.cropped.fluid} alt={title} />
          </button>
        )
      })}

      {modalState.modalOpen ? (
        <Modal
          id="imgModal"
          isOpen={modalState.modalOpen}
          contentLabel="Image"
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => toggleModal(null)}
          className="imgModal"
          overlayClassName="overlay"
          closeTimeoutMS={250}
        >
          <FontAwesomeIcon
            onClick={() => toggleModal(0)}
            onKeyUp={() => toggleModal(0)}
            icon={faTimesCircle}
            alt="Close"
            className="modalCloseButton"
          />

          <Carousel
            autoplay={false}
            pauseOnHover={true}
            withoutControls={false}
            swiping={true}
            transitionMode="scroll"
            slideWidth={1}
            speed={500}
            dragging={false}
            enableKeyboardControls={true}
            wrapAround={true}
            heightMode="max"
            className="imgModalContent"
            edgeEasing="easeElasticInOut"
            // renderBottomCenterControls={(props) => <PagingDots {...props} />}
          >
            <Img
              fadeIn={true}
              className="carouselImage"
              fluid={gallery[modalState.modalImgIndex].localFile.resized.fluid}
              alt={gallery[modalState.modalImgIndex].title}
              style={{
                maxWidth:
                  gallery[modalState.modalImgIndex].localFile.resized.fluid
                    .presentationWidth,
              }}
              imgStyle={{ width: "auto" }}
            />
            {gallery
              .filter((localFile, index) => index !== modalState.modalImgIndex)
              .map(({ title, localFile }, index) => (
                <Img
                  key={index}
                  className="carouselImage"
                  fluid={localFile.resized.fluid}
                  fit={"fill"}
                  alt={title}
                  style={{
                    maxWidth: localFile.resized.fluid.presentationWidth,
                  }}
                  imgStyle={{ width: "auto" }}
                />
              ))}
          </Carousel>
        </Modal>
      ) : null}
    </div>
  )
}

/**
 * Paging Dots component. This is where carousel dots are customized.
 * 
 * Currently unused (commented out in Carousel component props above)
 * 
 * @see {https://github.com/FormidableLabs/nuka-carousel/issues/382|Customizing Paging Dots Github Issue}
 * @param {*} props 
 */

// const PagingDots = (props) => (
//   <ul>
//     {PagingDots.getIndexes(props.slideCount, props.slidesToScroll).map(index => {
//       return (
//         <li key={index}>
//           <button
//             style={{ opacity: props.currentSlide === index ? 1 : 0.5  }}
//             onClick={props.goToSlide.bind(null, index)}
//           >
//             &bull;
//           </button>
//         </li>
//       );
//     })}
//   </ul>
// );

// PagingDots.getIndexes = (count, inc) => {
//   const arr = [];
//   for (let i = 0; i < count; i += inc) {
//     arr.push(i);
//   }
//   return arr;
// };

export default Page
